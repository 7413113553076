'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { msg } from '@/services/isomorphic/I18NService';
import type { FC, PropsWithChildren } from 'react';
import type IStylable from '../../../traits/IStylable';
import { Icon, IconTypes } from '../../Icon';

import S from './styles.module.scss';
import { general_close } from "@/lang/__generated__/ahnu/general_close";

interface IModalHeaderProps extends PropsWithChildren, IStylable {
  /** Called when the close button is clicked. */
  onClose: () => void;

  /**
   * Class to be applied to the inner container of the modal header.
   */
  containerClass?: string;

  /**
   * Class to be applied to the close icon.
   */
  iconClass?: string;

  /**
   * IconType to be added to the close button.
   *
   * @default IconType.Close
   */
  closeIcon?: IconTypes;
}

/**
 * Modal Header responsible to hold title and close button.
 */
export const ModalHeader: FC<IModalHeaderProps> = ({
  className,
  containerClass,
  iconClass,
  style,
  onClose,
  children,
  closeIcon = IconTypes.Close
}) => {
  return (
    <div className={className} style={style}>
      <div className={classes(S.container, containerClass)}>
        {children}
        <Icon
          className={classes(S.modalClose, iconClass)}
          title={msg(general_close)}
          icon={closeIcon}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';
import { uiImages } from '../../registry/data';

/**
 * Register the ContentBlock component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(async () => import('./index'))), {
    name: 'Overlay',
    canHaveChildren: true,
    image: uiImages.cubeImage,
    inputs: [
      {
        name: 'enabled',
        type: 'boolean',
        defaultValue: true
      },
      {
        name: 'color',
        type: 'color',
        defaultValue: 'rgba(0, 0, 0, 0.5)'
      }
    ]
  });
};

export default register;

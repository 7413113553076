'use client';

import type { IPlayerSettings } from '@/services/models/Content';
import type { AnyFunction } from '@/type-utils';
import {
  exitFullScreen,
  getFullScreenElement,
  requestFullScreen
} from '@/utils/fullscreen-utils';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useInsertionEffect,
  useMemo,
  useRef,
  useState
} from 'react';

/** Represents the current state of the media player. */
export interface IMediaPlayerState {
  /** Whether the media is currently playing. */
  readonly isPlaying: boolean;
  /** Whether the media is currently muted. */
  readonly isMuted: boolean;
  /** Whether the media is currently in fullscreen mode. */
  readonly isFullscreen: boolean;
  /** The current playback position of the media, in seconds. */
  readonly currentTime: number;
  /** The total duration of the media, in seconds. */
  readonly duration: number;
  /** Whether the media is currently loading. */
  readonly isLoading: boolean;
  /** Whether the media should automatically play when it's mounted. */
  readonly shouldAutoPlay: boolean;
  /** Whether the media controls should be shown. */
  readonly showControls: boolean;
  /** Whether the media should loop when it reaches the end. */
  readonly shouldLoop: boolean;
  /** Whether the media should pause when it's not in view. */
  readonly shouldPauseOffscreen: boolean;
}

/**
 * A helper for getting the initial media player state
 * from given player settings.
 * @param settings - The player settings.
 * @returns The initial media player state.
 */
export function getInitialMediaPlayerState(
  settings?: IPlayerSettings
): IMediaPlayerState {
  const {
    muted = false,
    autoplay = false,
    controls = false,
    loop = false,
    pauseWhenOffscreen = true
  } = settings ?? {};

  return {
    isPlaying: false,
    isMuted: muted,
    isFullscreen: false,
    isLoading: true,
    currentTime: 0,
    duration: 0,
    shouldAutoPlay: autoplay,
    showControls: controls,
    shouldLoop: loop,
    shouldPauseOffscreen: pauseWhenOffscreen
  };
}

/** Represents the actions that can be performed on the media player. */
export interface IMediaPlayerActions {
  /**
   * Requests the media to play as a user-initiated action.
   * @returns A promise that resolves when the media starts playing.
   */
  play: () => Promise<void>;
  /** Pauses the media as a user-initiated action. */
  pause: () => void;
  /**
   * Requests the media to enter fullscreen.
   * @returns A promise that resolves when the media enters fullscreen.
   */
  enterFullscreen: () => Promise<void>;
  /**
   * Requests the media to exit fullscreen.
   * @returns A promise that resolves when the media exits fullscreen.
   */
  exitFullscreen: () => Promise<void>;
  /** Mutes the media. */
  mute: () => void;
  /** Unmutes the media. */
  unmute: () => void;
}

/** A context which provides the current state of the media player. */
export const MediaPlayerStateContext = createContext<IMediaPlayerState | null>(
  null
);

/** A context which provides the actions that can be performed on the media player. */
export const MediaPlayerActionsContext =
  createContext<IMediaPlayerActions | null>(null);

/** A context which provides a function to attach the media element to the media player. */
export type AttachMediaElementFn = (mediaElt: HTMLMediaElement | null) => void;
export const MediaPlayerAttachElementContext =
  createContext<AttachMediaElementFn | null>(null);

/**
 * Gets the actions of the nearest media player.
 * @returns The media player actions.
 * @throws An error if this is not called within a `MediaPlayer` subtree.
 */
export function useMediaPlayerActions(): IMediaPlayerActions {
  const actions = useContext(MediaPlayerActionsContext);
  if (!actions) {
    throw new Error('MediaPlayerActionsContext is not provided.');
  }
  return actions;
}

/**
 * Gets the state of the nearest media player.
 * @returns The media player state.
 * @throws An error if this is not called within a `MediaPlayer` subtree.
 */
export function useMediaPlayerState(): IMediaPlayerState {
  const state = useContext(MediaPlayerStateContext);
  if (!state) {
    throw new Error('MediaPlayerStateContext is not provided.');
  }
  return state;
}

/**
 * Gets the attach element function for the nearest media player.
 * @returns The attach element function.
 * @throws An error if this is not called within a `MediaPlayer` subtree.
 */
export function useMediaPlayerAttachElement(): AttachMediaElementFn {
  const attachElement = useContext(MediaPlayerAttachElementContext);
  if (!attachElement) {
    throw new Error('MediaPlayerAttachElementContext is not provided.');
  }
  return attachElement;
}

/** Represents props for the `MediaPlayer` component. */
interface IMediaPlayerProps extends PropsWithChildren {
  /** The media player settings. */
  settings?: IPlayerSettings;
}

/**
 * The media player provider.
 * @param children - The children.
 */
export const MediaPlayer: FC<IMediaPlayerProps> = ({ settings, children }) => {
  const [mediaElt, setMediaElt] = useState<HTMLMediaElement | null>(null);

  // This is stored in state so that we only compute it on initial render,
  // and it should be preserved even if this component suspends.
  const [defaultMediaPlayerState] = useState(() =>
    getInitialMediaPlayerState(settings)
  );

  const [mediaPlayerState, setMediaPlayerState] = useState(
    defaultMediaPlayerState
  );

  /**
   * **Note to future developers**: You might be looking at this and thinking
   * "Why are we deriving all the state from the media element? Shouldn't the
   * state inform the UI, not the other way around?".
   *
   * Well, the problem is that React provides a very minimal API for rendering
   * media elements. Most of the element's internal state can only be accessed
   * and updated imperatively. Therefore, instead of syncing the media element
   * to the React state, we must sync our React state to the media element.
   *
   * As for why we have a single callback for updating _all_ of the state, it's
   * mainly because we saw race conditions between when the browser would fire
   * media events and when the event listeners were attached. This way, if we
   * miss one event and state becomes stale, we will fix it on the next event.
   */
  const updateMediaState = useCallback((): void => {
    if (!mediaElt) {
      setMediaPlayerState(defaultMediaPlayerState);
      return;
    }

    setMediaPlayerState((state) => ({
      ...state,
      isPlaying: !mediaElt.paused,
      isMuted: mediaElt.muted,
      isFullscreen: getFullScreenElement() === mediaElt,
      isLoading: mediaElt.readyState < HTMLMediaElement.HAVE_ENOUGH_DATA,
      currentTime: mediaElt.currentTime,
      duration: mediaElt.duration
    }));
  }, [defaultMediaPlayerState, mediaElt]);

  // Business Requirement: The media should be configurable to pause when it's not in view.
  const { shouldPauseOffscreen, shouldAutoPlay } = defaultMediaPlayerState;
  // Although the media may automatically pause when it's not in view,
  // we need to additionally track if the user has paused the media manually
  // to ensure that it doesn't start playing again when it's back in view.
  const [isUserPaused, setIsUserPaused] = useState(!shouldAutoPlay);
  /**
   * To avoid re-creating the `IntersectionObserver` every time the `isUserPaused`
   * state changes, we define its callback as an "effect event".
   *
   * This is necessary to fix a bug that prevented the media from resuming playback
   * when the user unpaused the media while it was offscreen. The bug occurred because
   * `IntersectionObserver` callbacks always fire the first render cycle after `observe()`
   * is called. As a result, when `isUserPaused` changed from `true` to `false`, a new
   * `IntersectionObserver` was created, and if the media was offscreen at the time
   * `observe()` was called, the callback would immediately pause the media again.
   *
   * However, with `useEffectEvent`, the callback function is now non-reactive
   * to the `isUserPaused` state.
   */
  const pauseMediaIfOffscreen = useEffectEvent<IntersectionObserverCallback>(
    ([entry]) => {
      if (entry.isIntersecting) {
        // only resume playing if the user hasn't paused the media manually
        if (!isUserPaused) mediaElt!.play();
      } else mediaElt!.pause();
    }
  );

  useEffect(
    function attachPauseOffscreenObserver() {
      if (!mediaElt) return undefined;
      if (!shouldPauseOffscreen) return undefined;

      const observer = new IntersectionObserver(pauseMediaIfOffscreen, {
        threshold: 0.5
      });

      observer.observe(mediaElt);

      return () => {
        observer.disconnect();
      };
    },
    [pauseMediaIfOffscreen, mediaElt, shouldPauseOffscreen]
  );

  useEffect(
    function attachMediaEventListeners() {
      if (!mediaElt) return undefined;

      const abortController = new AbortController();
      const { signal } = abortController;

      /* eslint-disable @eslint-react/web-api/no-leaked-event-listener
          -- This uses the AbortController pattern instead. */
      mediaElt.addEventListener('timeupdate', updateMediaState, { signal });
      mediaElt.addEventListener('play', updateMediaState, { signal });
      mediaElt.addEventListener('pause', updateMediaState, { signal });
      mediaElt.addEventListener('volumechange', updateMediaState, { signal });
      mediaElt.addEventListener('fullscreenchange', updateMediaState, {
        signal
      });
      /* eslint-enable @eslint-react/web-api/no-leaked-event-listener */

      return () => {
        abortController.abort();
      };
    },
    [updateMediaState, mediaElt]
  );

  const mediaPlayerActions = useMemo<IMediaPlayerActions>(
    () => ({
      play: async () => {
        setIsUserPaused(false);
        await mediaElt?.play();
        updateMediaState();
      },
      pause: () => {
        setIsUserPaused(true);
        mediaElt?.pause();
        updateMediaState();
      },
      enterFullscreen: async () => {
        if (!mediaElt) return;
        await requestFullScreen(mediaElt);
        updateMediaState();
      },
      exitFullscreen: async () => {
        await exitFullScreen();
        updateMediaState();
      },
      mute: () => {
        if (mediaElt) mediaElt.muted = true;
        updateMediaState();
      },
      unmute: () => {
        if (mediaElt) mediaElt.muted = false;
        updateMediaState();
      }
    }),
    [updateMediaState, mediaElt]
  );

  return (
    <MediaPlayerAttachElementContext.Provider value={setMediaElt}>
      <MediaPlayerStateContext.Provider value={mediaPlayerState}>
        <MediaPlayerActionsContext.Provider value={mediaPlayerActions}>
          {children}
        </MediaPlayerActionsContext.Provider>
      </MediaPlayerStateContext.Provider>
    </MediaPlayerAttachElementContext.Provider>
  );
};

/**
 * A polyfill of the experimental `useEffectEvent` React hook.
 *
 * This returns a "type of function" known as an "Effect Event".
 * Effect events provide a way to separate non-reactive logic from
 * the reactive logic of an effect. They achieve this by always
 * referring to the latest version of any captured values. As a result,
 * effect events are conceptually similar to event handlers, however
 * they must only be triggered within effects.
 *
 * This hook was originally proposed in {@link https://github.com/reactjs/rfcs/pull/220 RFC: useEvent}, but the scope of
 * the feature and its semantics have slightly changed since then.
 *
 * **Note**: Given that this is an experimental feature of React
 * and not guaranteed to be a long-term solution to the underlying
 * problem, this hook is only available within this module.
 *
 * @param fn - A function to treat as an effect event.
 * @returns A stable effect event function.
 * @see {@link https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event Declaring an Effect Event}
 * @see {@link https://react.dev/learn/separating-events-from-effects#limitations-of-effect-events Limitations of Effect Events}
 * @see {@link https://github.com/bluesky-social/social-app/blob/main/src/lib/hooks/useNonReactiveCallback.ts Source}
 */
function useEffectEvent<T extends AnyFunction>(fn: T): T {
  const ref = useRef<T>(fn);
  useInsertionEffect(() => {
    ref.current = fn;
  }, [fn]);
  return useCallback<T>(
    ((...args) => {
      const f = ref.current;
      return f(...args);
    }) as T,
    []
  );
}

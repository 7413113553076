import { Input } from '@builder.io/sdk';

/**
 * Fit for the image.
 */
export const fit = {
  name: 'fit',
  type: 'text',
  enum: ['contain', 'cover', 'fill', 'none', 'scale-down'],
  defaultValue: 'contain',
  helperText:
    'Defines how the content of a media element should be resized to fit its container.'
} satisfies Input;

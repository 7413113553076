'use client';

import { userPrefersReducedMotion } from '@/next-utils/browser/preferences';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { Nullable } from '@/type-utils';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useState
} from 'react';
import IStylable from '../../traits/IStylable';
import ITestIdentifiable from '../../traits/ITestIdentifiable';
import { Button } from '../Button';
import { Icon, IconAnimations, IconTypes } from '../Icon';

import S from './styles.module.scss';
import { general_collapse } from "@/lang/__generated__/ahnu/general_collapse";
import { general_expand } from "@/lang/__generated__/ahnu/general_expand";

/** The possible variants of the accordian. */
export type AccordionVariant =
  | 'pdp-section'
  | 'left-button'
  | 'collection'
  | 'category-title'
  | 'card';
export interface IAccordionProps
  extends PropsWithChildren,
    IStylable,
    ITestIdentifiable {
  /** Title for coupon code accordion. */
  title: ReactNode | string;

  /** Styling to be applied to the title component. */
  titleClassName?: string;

  /** Size to be applied to the toggle button component. */
  toggleSize?: string | number;

  /** The icon used to close the accordion. */
  iconUp?: Nullable<IconTypes>;

  /** The class used for iconUp, if desired. */
  iconUpAnimation?: IconAnimations;

  /** The icon used to open the accordion. */
  iconDown?: Nullable<IconTypes>;

  /** The class used for iconDown, if desired. */
  iconDownAnimation?: IconAnimations;

  /** Should the accordion start expanded. */
  startsExpanded?: boolean;

  /** The styling variant. */
  variant?: AccordionVariant;

  /** Toggle children. */
  toggleChildren?: ReactNode;

  /** Whether to animate the open/close process. */
  animate?: boolean;

  /** Whether the accordion children are rendered lazily, i.e. only when the accordion is expanded. */
  lazy?: boolean;
}

/**
 * Gets the container styling based on the variant. This applies a specific possible
 * styling arrangement as opposed to allowing any class to come from the parent
 * component.
 * @param variant - The variant prop coming into the component.
 * @returns A specific class to be added to the container.
 */
const getStylingFromVariant = (variant: AccordionVariant): string => {
  switch (variant) {
    case 'pdp-section':
      return S.container;
    case 'left-button':
      return S.reverseContainer;
    case 'collection':
      return S.collection;
    case 'category-title':
      return S.categoryTitle;
    case 'card':
      return S.cardContainer;
    default:
      return '';
  }
};

/**
 * Accordion component used for footer.
 */
export const Accordion: FunctionComponent<IAccordionProps> = ({
  title,
  titleClassName,
  toggleSize = '100%',
  iconUp = IconTypes.ChevronUp,
  iconUpAnimation,
  iconDown = IconTypes.ChevronDown,
  iconDownAnimation,
  startsExpanded = false,
  variant = 'pdp-section',
  children,
  className,
  toggleChildren,
  datatestID,
  animate = false,
  lazy = false
}) => {
  const [expanded, setExpanded] = useState(startsExpanded);

  const icon = useMemo(() => {
    const up = iconUp && <Icon animation={iconUpAnimation} icon={iconUp} />;
    const down = iconDown && (
      <Icon animation={iconDownAnimation} icon={iconDown} />
    );
    return expanded ? up : down;
  }, [expanded]);

  return (
    <div
      className={classes(className, getStylingFromVariant(variant))}
      data-testid={datatestID}
    >
      <Button
        className={S.button}
        style={{ width: toggleSize }}
        variant="text"
        aria-expanded={expanded}
        onClick={() => setExpanded(!expanded)}
        title={expanded ? msg(general_collapse) : msg(general_expand)}
        ariaLabel={expanded ? msg(general_collapse) : msg(general_expand)}
      >
        <div className={titleClassName}>{title}</div>
        {toggleChildren || null}
        <div className={S.icon}>{icon}</div>
      </Button>
      <div>
        {animate && !userPrefersReducedMotion() && (
          <AnimatePresence>
            <motion.div
              initial={{
                height: 0,
                opacity: 0
              }}
              transition={{
                ease: 'easeInOut'
              }}
              animate={{
                height: expanded ? 'auto' : 0,
                opacity: expanded ? 1 : 0
              }}
              exit={{
                height: 0,
                opacity: 0
              }}
              key="accordion-content"
            >
              {lazy && !expanded ? null : children}
            </motion.div>
          </AnimatePresence>
        )}
        {!animate && (
          <div className={classNames(expanded ? S.open : S.closed, S.content)}>
            {lazy && !expanded ? null : children}
          </div>
        )}
      </div>
    </div>
  );
};

'use client';

import MediaService from '@/services/isomorphic/MediaService';
import { TrackKinds } from '@/services/models/Media/Track';
import type { IVideo } from '@/services/models/Media/Video';
import { isNullOrEmpty } from '@/utils/null-utils';
import Image from 'next/image';
import { useContext, type FC } from 'react';
import type IStylable from '../../traits/IStylable';
import { SkipRenderOnServer } from '../Breakpoints/utils/SkipRenderOnServer';
import {
  MediaPlayerAttachElementContext,
  MediaPlayerStateContext
} from './MediaPlayer';

import S from './styles.module.scss';

/**
 * Check if a track is a caption track.
 */
export interface IVideoProps extends IStylable {
  /** Video model or DTO to display. */
  video: IVideo;

  /**
   * When `true`, the video will be considered high priority and preload.
   */
  priority?: boolean;
}

/**
 * Video component. This component is a wrapper around the HTML5 video element.
 * Video behavior can be hoisted to the nearest parent `MediaPlayerProvider` component.
 * @returns Video component.
 * @throws An error if this this is not rendered within a `MediaPlayerProvider`.
 */
export const Video: FC<IVideoProps> = ({ video, priority = false }) => {
  const { poster, src, captions } = video;

  // use context rather than the custom hook to avoid erroring
  // and allow the video to work outside of a MediaPlayer
  const attachMediaElement = useContext(MediaPlayerAttachElementContext);
  const mediaPlayerState = useContext(MediaPlayerStateContext) ?? {
    isMuted: video.settings?.muted ?? false,
    showControls: video.settings?.controls ?? false,
    shouldLoop: video.settings?.loop ?? false,
    shouldAutoPlay: video.settings?.autoplay ?? false
  };

  const { isMuted, showControls, shouldLoop, shouldAutoPlay } =
    mediaPlayerState;

  return (
    <div className={S.videoContainer}>
      {/* We use an <img> tag for the poster because Safari seems to not cache the result
      when using the poster attribute on the video element. That caused the poster to flash
      when the optimized version "took over", which led to poor UX. However, now the poster
      image can be cached, and it remains visible until the video frames load. */}
      {!isNullOrEmpty(poster) && (
        <Image className={S.poster} src={poster} alt="" priority fill />
      )}
      {/* Skip rendering on the server to prevent the browser from downloading videos
      that will not be used (e.g. when the video is only displayed on mobile). This
      also enables parent components to measure the video's container size before
      actually rendering the video element so that they can then choose the optimal
      quality for the video. */}
      <SkipRenderOnServer>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption -- Not sure why
      this is throwing. TrackKinds is set to captions. */}
        <video
          ref={attachMediaElement}
          controls={showControls}
          autoPlay={shouldAutoPlay}
          className={S.video}
          muted={isMuted}
          loop={shouldLoop}
          crossOrigin=""
          preload={priority ? 'auto' : 'metadata'}
          onError={() => {
            MediaService.reportMediaError(video);
          }}
          playsInline
        >
          <source src={src} type="video/mp4" />
          {/* TODO: Make this so tracks can be added recursively. */}
          {captions && captions.src !== '' && (
            <track
              kind={captions.kind ?? TrackKinds.Captions}
              src={captions.src ?? ''}
              srcLang={captions.srclang.split('-')[0]}
              label={captions.label}
              default
            />
          )}
        </video>
      </SkipRenderOnServer>
    </div>
  );
};

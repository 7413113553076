import { Input } from '@builder.io/sdk';

/**
 * Overlay for the image.
 */
export const overlay = {
  name: 'overlay',
  type: 'boolean',
  defaultValue: false,
  description: 'Whether the media should be displayed with a black overlay.'
} satisfies Input;

// Registers the About Us page component with Builder.io.
import registerAboutPage from '@/react/components/templates/AboutPage/register';

// Registers the component menu with Builder.io.
import { registerMenu } from './menu-registry';

/** Registered Components. */
import registerFragmentParser from '../../utility/FragmentParser/register';
import registerBreadCrumbs from '../../navigation/Breadcrumbs/register';
import registerButtons from '../../core-ui/Button/register';

import registerTextBlock from '../core/TextBlock/register';
import registerIconBlock from '../core/IconBlock/register';
import registerContentBlock from '../core/ContentBlock/register';
import registerContentMedia from '../core/ContentMedia/register';
import registerWrapper from '../core/Wrapper/register';
import registerModalLink from '../core/ModalLink/register';

import registerScrollEffectBlock from '../main/ScrollEffectBlock/register';
import registerSwappableImage from '../main/SwappableImage/register';
import registerContentSlider from '../main/ContentSlider/register';
import registerParallaxBlock from '../main/ParallaxBlock/register';
import registerStyleBlock from '../main/StyleBlock/register';
import registerParallaxColumns from '../main/ParallaxColumns/register';
import registerLinkedMedia from '../main/LinkedMedia/register';
import registerCountdown from '../main/Countdown/register';
import registerOverlay from '../main/Overlay/register';

import registerHeaderBreadcrumbs from '../page/HeaderBreadcrumbs/register';
import registerLandingContent from '../page/LandingContent/register';

import registerContentExperience from '../personalization/ContentExperience/register';

import registerContentProductTile from '../product/ContentProductTile/register';
import registerContentProductSwatches from '../product/ContentProductSwatches/register';

/**
 * This registers all components with Builder.io. If it is imported directly, like
 * in the docs, it will be ignored entirely.
 * @see https://www.builder.io/c/docs/custom-components-setup
 *
 * Additionally it runs the menu registry function.
 * @see https://www.builder.io/c/docs/sidebar-menu
 */
export const registerComponents = (): void => {
  // Non-content components.
  registerFragmentParser();
  registerBreadCrumbs();
  registerButtons();

  // Core.
  registerTextBlock();
  registerIconBlock();
  registerContentBlock();
  registerContentMedia();
  registerWrapper();
  registerModalLink();

  // Main.
  registerSwappableImage();
  registerScrollEffectBlock();
  registerContentSlider();
  registerParallaxBlock();
  registerStyleBlock();
  registerParallaxColumns();
  registerLinkedMedia();
  registerCountdown();
  registerOverlay();

  // Page.
  registerLandingContent();
  registerHeaderBreadcrumbs();
  registerAboutPage();

  // Personalization.
  registerContentExperience();

  // Product.
  registerContentProductTile();
  registerContentProductSwatches();

  // Menu registry.
  registerMenu();
};

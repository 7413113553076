/**
 * A helper for getting the current fullscreen element, with support for older browsers.
 * @returns The current fullscreen element, or `null` if there is none.
 */
export function getFullScreenElement(): Element | null {
  return (
    // eslint-disable-next-line compat/compat -- Support older browsers with the vendor-prefixed versions below.
    document.fullscreenElement ??
    document.webkitFullscreenElement ??
    document.mozFullScreenElement ??
    null
  );
}

/**
 * A helper for checking if fullscreen mode is available, with support for older browsers.
 * @returns `true` if fullscreen mode is available, `false` otherwise.
 */
export function isFullScreenEnabled(): boolean {
  return (
    // eslint-disable-next-line compat/compat -- Support older browsers with the vendor-prefixed versions below.
    document.fullscreenEnabled ??
    document.webkitFullscreenEnabled ??
    document.mozFullScreenEnabled ??
    false
  );
}

/**
 * A helper for displaying an element in fullscreen mode, with support for older browsers.
 * @param element - The element to display in fullscreen mode.
 * @returns A promise that resolves when the element enters fullscreen mode.
 */
export async function requestFullScreen(element: Element): Promise<void> {
  return (
    // eslint-disable-next-line compat/compat -- Support older browsers with the vendor-prefixed versions below.
    element.requestFullscreen?.() ??
    element.webkitRequestFullscreen?.() ??
    element.mozRequestFullScreen?.() ??
    Promise.reject(new TypeError('Fullscreen mode is not supported.'))
  );
}

/**
 * A helper for exiting fullscreen mode, with support for older browsers.
 * @returns A promise that resolves when the document exits fullscreen mode.
 */
export async function exitFullScreen(): Promise<void> {
  return (
    // eslint-disable-next-line compat/compat -- Support older browsers with the vendor-prefixed versions below.
    document.exitFullscreen?.() ??
    document.webkitExitFullscreen?.() ??
    document.mozCancelFullScreen?.() ??
    Promise.reject(new TypeError('Fullscreen mode is not supported.'))
  );
}

import { Input } from '@builder.io/sdk';

/**
 * Overlay color for the image.
 */
export const overlayColor = {
  name: 'overlayColor',
  type: 'color',
  defaultValue: 'inherit'
} satisfies Input;

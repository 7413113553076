'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { msg } from '@/services/isomorphic/I18NService';
import { ContentVideoModel } from '@/services/models/Media/ContentVideo';
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Icon, IconTypes } from '../../../../core-ui/Icon';
import { Spinner } from '../../../../core-ui/Spinner';
import { Video } from '../../../../core-ui/Video';
import {
  useMediaPlayerActions,
  useMediaPlayerState
} from '../../../../core-ui/Video/MediaPlayer';
import type { IContentVideoProps } from './IContentVideoProps';
import { ProgressIndicator } from './ProgressIndicator';

import S from './styles.base.module.scss';
import { content_video_fullscreen } from "@/lang/__generated__/ahnu/content_video_fullscreen";
import { content_video_pause } from "@/lang/__generated__/ahnu/content_video_pause";
import { content_video_play } from "@/lang/__generated__/ahnu/content_video_play";

/**
 * Component that displays a video with a custom overlay ui.
 * This component is used when no parent layout variant handles its rendering.
 */
export const ContentVideo: FunctionComponent<IContentVideoProps> = ({
  video,
  className: wrapperClass = ''
}) => {
  const videoDTO = ContentVideoModel.from(
    ContentVideoModel.from(video).toDTO()
  );

  const { isPlaying, isLoading, isFullscreen, currentTime, duration } =
    useMediaPlayerState();
  const { pause, play, enterFullscreen } = useMediaPlayerActions();

  /**
   * Whether or not to include a fullscreen icon.
   */
  const showFullscreenIcon = useBrandLocaleValue<boolean>(
    () => ({
      default: false,
      AHNU: true
    }),
    []
  );
  /**
   * Icon to use, if we we're showing the fullscreen icon.
   */
  const FullscreenIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.FullScreen,
      AHNU: IconTypes.FullScreenLight
    }),
    []
  );
  /**
   * Whether or not to display playback progress.
   */
  const showPlaybackProgress = useBrandLocaleValue<boolean>(
    () => ({
      default: false,
      AHNU: true
    }),
    []
  );
  /**
   * Whether or not to display the video title.
   */
  const showTitle = useBrandLocaleValue<boolean>(
    () => ({
      default: false,
      AHNU: true
    }),
    []
  );

  const [isTitleVisible, setIsTitleVisible] = useState<boolean>(showTitle);
  useEffect(() => {
    // Once playback has started, hide the title.
    if (isPlaying) {
      setIsTitleVisible(false);
    }
  }, [isPlaying]);

  /**
   * The container ref is used to determine the size of the video, and passes
   * it to the sibling overlay.
   */
  const containerRef = useRef<HTMLDivElement>(null);
  /**
   * The container size is calculated only when the container ref is available.
   */
  const containerSize = useMemo(() => {
    if (containerRef.current) {
      return {
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight
      };
    }
    return { width: videoDTO.width, height: videoDTO.height };
  }, [
    containerRef?.current?.offsetWidth,
    containerRef?.current?.offsetHeight,
    videoDTO.width,
    videoDTO.height
  ]);

  const handleEvent = (
    e: React.SyntheticEvent<Element>,
    callback: () => void
  ): void => {
    e.preventDefault();
    callback();
  };

  const fullscreenIcon = useMemo(() => {
    if (isLoading) {
      return <Spinner className={S.spinner} />;
    }
    // Check if fullscreen is supported
    if (document && !document.fullscreenEnabled) {
      return null;
    }
    return (
      <Icon
        onClick={(e) => {
          handleEvent(e, enterFullscreen as () => void);
        }}
        className={S.icon}
        icon={FullscreenIconType}
        ariaLabel={msg(content_video_fullscreen)}
      />
    );
  }, [isFullscreen, enterFullscreen]);

  /**
   * The player icon is a play/pause button that is rendered on top of the video.
   * It renders conditionally based on the playing state. It also handles the
   * loading state, display a spinner instead of the icon.
   * It's important here to include the "handle" callbacks as a part of the dependency
   * array, otherwise the component will not re-render when the callbacks change.
   */
  const playIconClasses = showPlaybackProgress
    ? classes(S.iconReducedSize, S.iconShift)
    : S.icon;
  const pauseIconClasses = showPlaybackProgress ? S.iconReducedSize : S.icon;
  const playerIcon = useMemo(() => {
    if (isLoading) {
      return <Spinner className={S.spinner} />;
    }

    const progress = duration > 0 ? currentTime / duration : 0;

    return isPlaying ? (
      <div className={S.iconContainer}>
        {showPlaybackProgress && <ProgressIndicator progress={progress} />}
        <Icon
          className={pauseIconClasses}
          icon={IconTypes.Pause}
          onClick={(e) => {
            handleEvent(e, pause);
          }}
          onKeyPress={(e) => {
            e.stopPropagation();
            handleEvent(e, pause);
          }}
          ariaLabel={msg(content_video_pause)}
        />
      </div>
    ) : (
      <div className={S.iconContainer}>
        {showPlaybackProgress && <ProgressIndicator progress={progress} />}
        <Icon
          className={playIconClasses}
          icon={IconTypes.Play}
          onClick={(e) => {
            handleEvent(e, play as () => void);
          }}
          onKeyPress={(e) => {
            e.stopPropagation();
            handleEvent(e, play as () => void);
          }}
          ariaLabel={msg(content_video_play)}
        />
      </div>
    );
  }, [isPlaying, pause, play, isLoading]);

  return (
    <div
      className={classes(S.container, wrapperClass)}
      ref={containerRef}
      style={
        {
          '--video-width': containerSize.width,
          '--video-height': containerSize.height
        } as React.CSSProperties
      }
    >
      <Video key={`content_video-${videoDTO.uuid}`} video={videoDTO} />
      {isLoading ? (
        <div className={S.loading}>
          <Spinner className={S.spinner} />
        </div>
      ) : (
        <div className={S.overlay}>
          <div className={S.icons}>
            {playerIcon}
            {showFullscreenIcon && fullscreenIcon}
          </div>
        </div>
      )}
      {isTitleVisible && (
        <div className={S.title}>
          <div className={S.titleText}>{videoDTO.title}</div>
        </div>
      )}
    </div>
  );
};

import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { uiImages } from '@/react/components/content/registry/data';
import { IconTypes } from '@/react/components/core-ui/Icon';

// Convert the IconTypes enum into an array of objects with `label` and `value` properties accepted by Builder.io `enum`.
const iconTypesIntoLabelValueObject = Object.keys(IconTypes).map((key) => ({
  label: key,
  value: IconTypes[key as keyof typeof IconTypes]
}));

/**
 * Register the IconBlock component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(async () => import('./index'))), {
    name: 'IconBlock',
    inputs: [
      {
        name: 'headline',
        type: 'richText',
        defaultValue: '',
        required: true,
        helperText: 'The headline of the icon block. A heading h2 will be used.'
      },
      {
        name: 'renderHeadlineAsString',
        type: 'boolean',
        defaultValue: false
      },
      {
        name: 'text',
        type: 'richText',
        defaultValue: '',
        required: true,
        helperText: 'The text of the icon block.'
      },
      {
        name: 'renderTextAsString',
        type: 'boolean',
        defaultValue: false
      },
      {
        name: 'icon',
        type: 'string',
        // The `enum` type accepts an array of objects with `label` and `value` properties.
        // We are using the enum key as the label to be displayed on builder, and will receive the value
        // of the icon to be displayed. We can improve later by adding a custom label for each icon and helper text descriptions
        // for each.
        enum: iconTypesIntoLabelValueObject,
        required: true,
        helperText:
          'The icon to display. The label here are the keys of the IconTypes enum. The value is the icon to be displayed.'
      },
      {
        name: 'wrapperClass',
        type: 'text',
        defaultValue: ''
      },
      {
        name: 'iconClass',
        type: 'text',
        defaultValue: ''
      }
    ],
    image: uiImages.cubeImage
  });
};

export default register;
